import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  arrayOf,
  bool,
  func,
  number,
  objectOf,
  oneOfType,
  string,
} from "prop-types";
import {
  chosenTimeSlotPropType,
  driverPropType,
  serviceAdvisorPropType,
} from "shared/prop-types";
import ClipLoader from "react-spinners/ClipLoader";
import Switch from "react-switch";

import {
  bookingDetailsChosenDropOffTimeSlotSelector,
  bookingDriversSelector,
  bookingIsJobReachableLoading,
  bookingJobDropOffReachable,
} from "store/selectors/booking-details-selectors";
import {
  settingsDealershipLatitudeLongitudeSelector,
  settingsDealershipShowCoDriver,
  settingsPickupIntegrationSegmentsNumberSelector,
} from "store/selectors/settings-selectors";
import {
  fetchAdvisors,
  initChecksJobDistance,
  resetJobReachable,
  setNewDriverJob,
  updateJob,
} from "store/actions/booking-details-actions";

import Alert from "components/common/Alert";
import DriversSelection from "components/common/Remote/ChooseDrivers/DriversSelection";
import LocationInput from "components/common/Remote/LocationInput";
import Modal from "components/common/Modal";
import TimeOfArrival from "components/common/BookingSteps/TimeOfArrival/TimeOfArrivalBooking";

import styles from "./styles.module.scss";

const EditDropOffJobModal = ({
  dealershipPosition,
  appointmentId,
  jobReachable,
  isJobReachableLoading,
  selectedAdvisor,
  pickUpJob,
  dropOffJob,
  chosenDropOffTimeSlot,
  drivers,
  isDropOff,
  checksJobDistance,
  resetJobReachableData,
  fetchTeamAdvisorsForServices,
  onClose,
  create,
  update,
  segmentsNumber,
  showCoDriver,
  customerAddress,
  customerAddressData,
}) => {
  const [selectedDriver, setSelectedDriver] = useState(dropOffJob.main_driver);
  const [selectedCoDriver, setSelectedCoDriver] = useState(dropOffJob.co_driver);
  const [additionalInfo, setAdditionalInfo] = useState(null);
  const [notes, setNotes] = useState(dropOffJob.notes);
  const [emptyAddress, setEmptyAddress] = useState(false);
  const [addressIsNotComplete, setAddressIsNotComplete] = useState(false);
  const [ignoreDistanceLimit, setIgnoreDistanceLimit] = useState(dropOffJob.distance_limit_ignored);

  useEffect(() => {
    fetchTeamAdvisorsForServices();
  }, []);

  useEffect(() => {
    if (!additionalInfo) {
      if (Object.keys(dropOffJob).length > 0) {
        setAdditionalInfo({
          dropOff: {
            address: dropOffJob.address,
            addressData: {
              address_city: dropOffJob.address_city,
              address_line1: dropOffJob.address_line1,
              address_line2: dropOffJob.address_line2,
              address_state: dropOffJob.address_state,
              address_zipcode: dropOffJob.address_zipcode,
            },
            distanceLimitIgnored: ignoreDistanceLimit,
            zone: dropOffJob.location_area,
            geolocation: dropOffJob.geolocation,
            overcapacity: dropOffJob.overcapacity,
          },
        });
      } else {
        setAdditionalInfo({
          dropOff: {
            address: pickUpJob?.address || customerAddress,
            addressData: {
              address_city: pickUpJob?.address_city || customerAddressData?.address_city,
              address_line1: pickUpJob?.address_line1 || customerAddressData?.address_line1,
              address_line2: pickUpJob?.address_line2 || customerAddressData?.address_line2,
              address_state: pickUpJob?.address_state || customerAddressData?.address_state,
              address_zipcode: pickUpJob?.address_zipcode || customerAddressData?.address_zipcode,
            },
            distanceLimitIgnored: ignoreDistanceLimit,
            zone: pickUpJob?.location_area,
            geolocation: pickUpJob?.geolocation,
            overcapacity: pickUpJob?.overcapacity,
          },
        });
      }
    }
  }, [dropOffJob]);

  const availableDrivers = () => drivers.filter(
    (item) => selectedCoDriver?.id !== item.id,
  );

  const availableCoDrivers = () => drivers.filter(
    (item) => selectedDriver?.id !== item.id,
  );

  const submit = () => {
    if (isDropOff) {
      update(
        {
          ...dropOffJob,
          appointment_id: appointmentId,
          address: additionalInfo.dropOff.address,
          ...additionalInfo.dropOff.addressData,
          collection_time: `${chosenDropOffTimeSlot.day.full_date} ${chosenDropOffTimeSlot.quarterSlot}`,
          main_driver: selectedDriver,
          co_driver: selectedCoDriver,
          notes,
          distance_limit_ignored: ignoreDistanceLimit,
          geolocation: additionalInfo.dropOff.geolocation,
          location_area: additionalInfo.dropOff.zone,
          overcapacity: chosenDropOffTimeSlot.overcapacity,
        },
      );
    } else {
      create({
        appointmentId,
        addressData: additionalInfo.dropOff.addressData,
        collectionTime: {
          date: chosenDropOffTimeSlot.day.full_date,
          time: chosenDropOffTimeSlot.quarterSlot,
        },
        jobType: "dropoff",
        mainDriverId: selectedDriver.id,
        coDriverId: selectedCoDriver?.id || null,
        notes,
        distance_limit_ignored: ignoreDistanceLimit,
        geolocation: additionalInfo.dropOff.geolocation,
        location_area: additionalInfo.dropOff.zone,
        overcapacity: chosenDropOffTimeSlot.overcapacity,
      });
    }

    onClose();
  };

  const onChangeHandle = useCallback(
    (updates, type) => {
      setAdditionalInfo({
        ...additionalInfo,
        ...updates,
      });

      setAddressIsNotComplete(false);

      const { addressData, geolocation } = updates.dropOff;

      if (addressData) {
        setEmptyAddress(false);

        const {
          address_line1,
          address_line2,
          address_city,
          address_state,
          address_zipcode,
        } = addressData;

        if (
          address_line1
          && address_line2
          && address_city
          && address_state
          && address_zipcode
        ) {
          checksJobDistance(addressData, geolocation, type);
        } else {
          setAddressIsNotComplete(true);
        }
      } else {
        setEmptyAddress(true);
        resetJobReachableData(false);
      }
    },
    [additionalInfo],
  );

  useEffect(() => {
    resetJobReachableData(ignoreDistanceLimit);
  }, [ignoreDistanceLimit]);

  const submitDisabled = () => !jobReachable
    || Object.keys(chosenDropOffTimeSlot).length === 0
    || !selectedDriver
    || Object.keys(selectedDriver).length === 0
    || emptyAddress
    || addressIsNotComplete;

  return (
    <Modal
      className={styles.editDropOffJobModal}
      title={`${isDropOff ? "Edit" : "Add"} Drop-off job`}
      cancelButtonText="Cancel"
      submitButtonText="Save"
      size="medium"
      onCancel={onClose}
      onSubmit={submit}
      submitDisabled={submitDisabled()}
    >
      <div className="alertContainer">
        {addressIsNotComplete && (
          <Alert
            className={styles.marginBottom}
            variant="error"
            text="The address is not complete"
          />
        )}
        {jobReachable !== null && emptyAddress && !addressIsNotComplete && (
          <Alert
            className={styles.marginBottom}
            variant="error"
            text="Address should be present"
          />
        )}
        {!isJobReachableLoading
        && jobReachable !== null
        && !emptyAddress
        && !addressIsNotComplete
        && (
          <Alert
            className={styles.marginBottom}
            variant={
              jobReachable
                ? "success"
                : "error"
            }
            text={
              jobReachable
                ? `Distance in limit for vehicle Drop-off (Service zone ${additionalInfo?.dropOff?.zone})`
                : "Location outside of the maximum distance limit for vehicle Drop-off"
            }
          />
        )}
      </div>
      {additionalInfo && (
        <LocationInput
          isDropOff
          isSameLocation={false}
          additionalInfo={additionalInfo}
          onChange={(updates) => onChangeHandle(updates, "dropoff")}
          dealershipPosition={dealershipPosition}
          segmentsNumber={segmentsNumber}
        />

      )}
      {additionalInfo
        && jobReachable !== null
        && (jobReachable === false || ignoreDistanceLimit)
        && (
          <div className={styles.ignorSwitchContainer}>
            <label
              className={styles.switchLabel}
              htmlFor="ignore-distance-limit-switch"
            >
              Allow service outside of the maximum distance limit
            </label>
            <Switch
              id="ignore-distance-limit-switch"
              className={styles.switch}
              onChange={() => setIgnoreDistanceLimit(!ignoreDistanceLimit)}
              checked={ignoreDistanceLimit}
              onColor="#36af5e"
              offColor="#dedee0"
              activeBoxShadow="0 0 2px 3px #0bcaf9"
              aria-labelledby="ignore-distance-limit-switch-label"
            />
          </div>
        )}
      <div className={styles.calendarContainer}>
        {!isJobReachableLoading && (
          <TimeOfArrival
            selectedAdvisor={selectedAdvisor}
            dropOffType
            ignoreStep
          />
        )}
      </div>
      <DriversSelection
        availableDrivers={availableDrivers()}
        selectedDriver={selectedDriver}
        setSelectedDriver={setSelectedDriver}
        availableCoDrivers={availableCoDrivers()}
        selectedCoDriver={selectedCoDriver}
        setSelectedCoDriver={setSelectedCoDriver}
        notes={notes}
        setNotes={setNotes}
        showCoDriver={showCoDriver}
      />
      {isJobReachableLoading && (
        <div className={styles.spinnerContainer}>
          <ClipLoader size={60} color="#0bcaf9" />
        </div>
      )}
    </Modal>
  );
};

EditDropOffJobModal.propTypes = {
  dealershipPosition: {
    lat: number,
    lng: number,
  }.isRequired,
  appointmentId: oneOfType([number, string]),
  isJobReachableLoading: bool,
  jobReachable: bool,
  selectedAdvisor: serviceAdvisorPropType,
  pickUpJob: objectOf(oneOfType([number, bool, string])).isRequired,
  dropOffJob: objectOf(oneOfType([number, bool, string])),
  chosenDropOffTimeSlot: chosenTimeSlotPropType,
  drivers: arrayOf(driverPropType),
  isDropOff: bool,
  checksJobDistance: func.isRequired,
  resetJobReachableData: func.isRequired,
  fetchTeamAdvisorsForServices: func.isRequired,
  onClose: func.isRequired,
  create: func.isRequired,
  update: func.isRequired,
  segmentsNumber: number.isRequired,
  showCoDriver: bool.isRequired,
  customerAddress: string,
  customerAddressData: objectOf(string),
};

EditDropOffJobModal.defaultProps = {
  appointmentId: null,
  selectedAdvisor: {},
  dropOffJob: {},
  chosenDropOffTimeSlot: {},
  drivers: null,
  isDropOff: false,
  isJobReachableLoading: false,
  jobReachable: null,
  customerAddress: null,
  customerAddressData: {},
};

const mapStateToProps = (state) => {
  return {
    drivers: bookingDriversSelector(state),
    dealershipPosition: settingsDealershipLatitudeLongitudeSelector(state),
    isJobReachableLoading: bookingIsJobReachableLoading(state),
    jobReachable: bookingJobDropOffReachable(state),
    chosenDropOffTimeSlot: bookingDetailsChosenDropOffTimeSlotSelector(state),
    segmentsNumber: settingsPickupIntegrationSegmentsNumberSelector(state),
    showCoDriver: settingsDealershipShowCoDriver(),
  };
};

const actions = {
  checksJobDistance: initChecksJobDistance,
  resetJobReachableData: resetJobReachable,
  fetchTeamAdvisorsForServices: fetchAdvisors,
  create: setNewDriverJob,
  update: updateJob,
};

export default connect(mapStateToProps, actions)(EditDropOffJobModal);
