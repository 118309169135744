import React, { useEffect, useState } from "react";
import { match as matchType } from "react-router-prop-types";
import {
  arrayOf,
  bool,
  func,
  node,
  objectOf,
  shape,
  string,
} from "prop-types";
import {
  serviceAdvisorPropType,
} from "shared/prop-types";
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import { isEmpty, isNil, or } from "ramda";
import { format, isAfter, isToday } from "date-fns";
import { NotificationContainer, NotificationManager } from "react-notifications";

import InternalNote from "components/pages/BookingDetailsPage/InternalNote";
import BookingDetailsPanel from "components/pages/BookingDetailsPage/BookingDetailsPanel";
import BookingDetailsSummary from "components/pages/BookingDetailsPage/BookingDetailsSummary";
import VideosPanel from "components/pages/BookingDetailsPage/VideosPanel";
import PageHeader from "components/common/PageHeader";
import Panel from "components/common/Panel";
import Accordion from "components/common/Accordion/BookingAccordion";
import Modal from "components/common/Modal";
import loadingGif from "assets/images/loading.gif";
import {
  bookingDetailsCustomerSelector,
  bookingDetailsDataSelector,
  bookingDetailsErrorSelector,
  bookingDetailsIsCheckedInSelector,
  bookingDetailsIsDropOffSelector,
  bookingDetailsIsROGeneratedSelector,
  bookingDetailsIsSmsSentSelector,
  bookingDetailsIsTriggerSmsLoadingSelector,
  bookingDetailsLoadingStateSelector,
  bookingDetailsQuestionnairesSelector,
  bookingDetailsSaveAppointmentErrorSelector,
  bookingDetailsSaveAppointmentLoadingSelector,
  bookingDetailsSelectedAdvisorSelector,
  bookingDetailsSendSmsSelector,
  bookingDetailsUpdatedWithoutDmsNotificationSelector,
  bookingDetailsisMobileTechnicianSelector,
  bookingVehicleData,
} from "store/selectors/booking-details-selectors";
import {
  deleteBooking,
  generateRO,
  resetBookingDetails,
  retrieveBookingDetails,
  retrieveDealershipDrivers,
  scheduleNewBooking,
  triggerCheckinSms,
  updateAppointment,
  updateBookingToArrived,
} from "store/actions/booking-details-actions";
import {
  initializeChat,
  initializeCheckIn,
} from "store/actions/checkin-chat-actions";
import { exit } from "store/actions/checkin-app-actions";
import {
  chatIsInitializedSelector,
  checkinAppointmentErrorSelector,
  checkinAppointmentLoadingSelector,
} from "store/selectors/checkin-chat-selectors";
import { settingsIsAutoGenerateROSelector } from "store/selectors/settings-selectors";

import {
  formatName,
  prepareCustomerPickupAddress,
  prepareCustomerPickupAddressData,
} from "shared/utils/common";
import { isMobileAndTablet } from "shared/utils/index";
import { extractErrorMessage } from "shared/utils/errorHandler";

import { AMERICAN_DATETIME_FORMAT } from "shared/utils/datetime";

import ArrowLeft from "assets/images/arrow/left.svg";
import Button from "components/common/Button";
import Alert from "components/common/Alert";
import AlternativeTransportationPanel from "./AlternativeTransportationPanel";
import LastAdvisor from "./BookingDetailsSummary/LastAdvisor";
import CheckinModal from "./CheckinModal";
import EditDropOffJobModal from "./EditDropOffJobModal";
import QuestionnaireModal from "./BookingDetailsPanel/QuestionnaireModal";
import ModifyBookingPage from "./ModifyBookingPage";

import styles from "./styles.module.scss";

const isTesting = localStorage.getItem("test_features");

const PageTitle = ({ name }) => (
  <>
    <h2>Bookings / </h2>
    <span>{name}</span>
  </>
);

PageTitle.propTypes = {
  name: string,
};

PageTitle.defaultProps = {
  name: "",
};

const BookingDetailsPage = ({
  booking,
  booking: {
    aasm_state,
    id: appointmentId,
    jobs,
    vehicle,
    customer,
    transport,
    questionnaire_answers,
    external_number,
    service_type,
  },
  isCheckedIn,
  isCheckinLoading,
  checkinError,
  isTriggerSmsLoading,
  isSmsSent,
  isAutoGenerateROenabled,
  isRoGenerated,
  isDropOff,
  isMobileTechnician,
  isChatInitialized,
  onInitializeChat,
  selectedAdvisor,
  isLoading,
  saveAppointmentLoading,
  onInitializeCheckin,
  fetchBookingDetails,
  match: {
    params: {
      guid,
      kind,
    },
  },
  retrieveDrivers,
  saveAppointmentError,
  updatedWithoutDmsNotification,
  resetBookingDetailsState,
  error,
  vehicleData,
  saveAppointment,
  inviteToCheckin,
  createRo,
  onScheduleNewBooking,
  updateToArrived,
  onDeleteBooking,
  onReset,
  history,
  currentCustomer,
  questionnaires,
}) => {
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isCheckinModalOpen, setICheckinModalOpen] = useState(false);
  const [isQuestionnaireModalOpen, setIsQuestionnaireModalOpen] = useState(false);
  const [isModifyBooking, setIsModifyBooking] = useState(false);
  const [showReassignDialog, setShowReassignDialog] = useState(false);
  const [editDropOffJob, setEditDropOffJob] = useState(false);

  const isCheckedInStatus = isCheckedIn || booking.status === "checked_in";
  const isArrived = aasm_state === "arrived";
  const isClosed = aasm_state === "closed";
  const isCanceled = aasm_state === "canceled";
  const isNotShown = aasm_state === "not_shown";
  const activePickUpJob = jobs.find((item) => item.aasm_state !== "cancelled" && item.job_type === "pickup") || {};
  const activeDropOffJob = jobs.find((item) => item.aasm_state !== "cancelled" && item.job_type === "dropoff") || {};
  const isBookable = customer && customer.id && vehicle && vehicle.id;
  const isChangeable = vehicle && vehicle.id;
  const isIOS = (/iPad|iPhone|iPod/.test(navigator.platform)
    || (navigator.platform === "MacIntel" && navigator.maxTouchPoints > 1))
    && !window.MSStream;
  const isRoCreated = !!booking.repair_order_number || isRoGenerated;
  const isGenerateROAvailable = !isAutoGenerateROenabled
    && !isRoCreated;
  const questionnaireAnswers = questionnaire_answers && questionnaire_answers.length > 0
    ? questionnaire_answers[0].response
    : null;
  const questionnaireSource = questionnaire_answers && questionnaire_answers.length > 0
    ? questionnaire_answers[0].created_by
    : null;
  const isQuestionnaireShown = (
    questionnaires.length > 0
    && !questionnaireAnswers
    && !isNotShown
    && !isCanceled
    && isMobileTechnician
    && (
      isToday(booking.appointment_datetime)
      || isAfter(booking.appointment_datetime, new Date())
    )
  );

  useEffect(() => {
    if (
      (or(isEmpty(booking), isNil(booking)) && !isLoading)
      || (!isEmpty(booking) && !isNil(booking) && !booking.guid)
      || (!isEmpty(booking) && !isNil(booking) && booking.guid && booking.guid !== guid)
      || (!isEmpty(booking) && !isNil(booking) && booking.status !== "checked_in")
    ) {
      fetchBookingDetails(guid, kind);
      onInitializeCheckin({
        guid,
      });
      retrieveDrivers();
    }
  }, []);

  useEffect(() => {
    if (isCheckedIn && !saveAppointmentLoading && !saveAppointmentError) {
      window.location.reload();
    }

    if (isRoGenerated) {
      NotificationManager.success("Repair Order Generated.", "Success");
      setTimeout(() => window.location.reload(), 1000);
    }

    if (isArrived) {
      NotificationManager.success("Customer Arrived", "Success");
      setTimeout(() => window.location.reload(), 1000);
    }

    if (updatedWithoutDmsNotification) {
      NotificationManager.warning(updatedWithoutDmsNotification, "Warning");
      setTimeout(() => window.location.reload(), 3000);
    }
  }, [isRoGenerated, updatedWithoutDmsNotification, isCheckedIn]);

  useEffect(() => () => {
    resetBookingDetailsState();
  }, []);

  if (isCanceled && !booking.canceled_by) {
    return <Redirect to="/bookings" />;
  }

  const cancelerName = () => (
    booking.canceled_by?.canceled_by_name || booking.canceled_by?.source
  );

  const prepareHeaderText = () => {
    const customerName = customer
      ? ` ${customer.first_name || ""} ${customer.last_name || ""}`
      : "";
    return `${external_number || ""} ${customerName}`;
  };

  const handleCheckinOpen = () => {
    if (!isChatInitialized) {
      onInitializeChat();
    }
    setICheckinModalOpen(true);
  };

  const errorComponent = () => {
    if (error || saveAppointmentError) {
      let text = error ? extractErrorMessage(error) : "";

      if (Array.isArray(saveAppointmentError)) {
        text += ` ${saveAppointmentError.map((item) => item.message)}`;
      }

      if (saveAppointmentError.message) {
        text += ` ${saveAppointmentError.message}`;
      }

      if (!booking.vehicle?.mileage && !text) {
        text = "Mileage is needed to generate RO.";
      }

      if (!booking.vehicle?.vin && !text) {
        text = "VIN is needed to generate RO.";
      }

      return (
        <div className="padding-top">
          <Alert variant="error" text={text} />
          {vehicleData.owner_customer_id && showReassignDialog && (
            <div className={styles.bookingReassignContainer}>
              <p>{`Do you want to reassign the appointment to vehicle owner ${vehicleData.owner_name}?`}</p>
              <div className={styles.buttonContainer}>
                <Button variant="brand" onClick={() => saveAppointment()}>Reassign</Button>
                <Button variant="destructive" onClick={() => setShowReassignDialog(false)}>No</Button>
              </div>
            </div>
          )}
        </div>
      );
    }

    return null;
  };

  const buttonTitle = () => {
    if (isArrived) {
      return "Arrived";
    } if (isCheckedInStatus) {
      return "Checked in";
    }

    return "Check-in";
  };

  const dropOffButton = () => {
    if (isDropOff) {
      const dropOffDateTime = new Date(activeDropOffJob.collection_time);
      const dropOffArrivedToDealership = activeDropOffJob.aasm_state === "arrived_to_dealership";

      if (dropOffDateTime < new Date() || dropOffArrivedToDealership) {
        return null;
      }
    }

    return (
      <Button
        variant="brand"
        leftIcon={isDropOff ? "editWhite" : "addWhite"}
        onClick={() => setEditDropOffJob(true)}
        disabled={!currentCustomer}
      >
        {`${isDropOff ? "Edit" : "Add"} Drop-off job`}
      </Button>
    );
  };

  const defaultPageTitle = () => (
    <>
      <Link
        to={{ pathname: "/bookings" }}
        className={styles.goBackButton}
      >
        <img src={ArrowLeft} alt="" />
        <PageTitle name={prepareHeaderText()} />
      </Link>
      {isNotShown && !isLoading && (
        <div className={styles.buttonsWrap}>
          <span className={styles.labelNotShown}>Not shown</span>
        </div>
      )}
      {isCanceled && (
        <div className={styles.canceledBlock}>
          <span className={styles.canceledBlockLabel}>
            Canceled
          </span>
          <span className={styles.canceledBlockText}>
            {`Canceled by: ${cancelerName()}`}
          </span>
          {booking.canceled_by?.canceled_at && (
            <span className={styles.canceledBlockText}>
              {format(booking.canceled_by?.canceled_at, AMERICAN_DATETIME_FORMAT)}
            </span>
          )}
        </div>
      )}
      {!isNotShown && !isCanceled && !isLoading && (
        <div className={styles.buttonsWrap}>
          {isCheckinLoading && (
            <img
              className={styles.buttonsLoading}
              src={loadingGif}
              alt="Loading ..."
            />
          )}
          {!isCheckinLoading && !saveAppointmentLoading && (
            <>
              {service_type !== "mobile_technician" && (isArrived || isClosed) && (
                dropOffButton()
              )}
              {!isClosed && (
                <Button
                  variant="success"
                  onClick={handleCheckinOpen}
                  disabled={checkinError || isCheckedInStatus || isArrived}
                >
                  {buttonTitle()}
                </Button>
              )}
              {!isCheckedInStatus && !isRoCreated && !isArrived && !isClosed && (
                <Button
                  variant="dark-outline"
                  onClick={() => inviteToCheckin()}
                  disabled={isTriggerSmsLoading || isSmsSent}
                >
                  {isSmsSent && "Invite sent"}
                  {isTriggerSmsLoading && "Sending sms..."}
                  {!isSmsSent && !isTriggerSmsLoading && "Invite to check-in"}
                </Button>
              )}
              {(isIOS || isMobileAndTablet()) && !isRoCreated && (
                <Button
                  variant="dark"
                  onClick={() => window.open("carmenarrive://")}

                >
                  Video inspection
                </Button>
              )}
              {isGenerateROAvailable && (
                <Button
                  variant="brand"
                  onClick={() => {
                    createRo(booking.id);
                    setShowReassignDialog(true);
                  }}
                >
                  Create RO
                </Button>
              )}
            </>
          )}
        </div>
      )}
    </>
  );

  const editedPageTitle = () => (
    <>
      <button
        type="button"
        onClick={() => setIsModifyBooking(false)}
        className={styles.goBackButton}
      >
        <img src={ArrowLeft} alt="" />
      </button>
      <h2>Modify or Reschedule the Appointment</h2>
    </>
  );

  return (
    <section className={styles.page}>
      <NotificationContainer />
      <PageHeader title={isModifyBooking ? editedPageTitle() : defaultPageTitle()} />
      <section className={styles.body}>
        {!isModifyBooking && (isLoading || saveAppointmentLoading) && (
          <Panel className={styles.loadingPanel}>
            Loading...
          </Panel>
        )}
        {!isModifyBooking && !isLoading && !saveAppointmentLoading && (
          <>
            {errorComponent()}
            <section className={styles.container}>
              <section className={styles.choicePanel}>
                <BookingDetailsPanel booking={booking} />
                {jobs.filter((job) => job.job_type === "pickup").length > 0 && (
                  <Accordion title="Pick-up details">
                    {jobs.filter((job) => job.job_type === "pickup").map((job) => (
                      <div className={styles.detailsBlock}>
                        <div className={styles.detailsBlockGroup}>
                          <section className={styles.detailsBlockItem}>
                            <span className={styles.detailsBlockLabel}>
                              Pick-up:
                            </span>
                            <span className={styles.detailsBlockValue}>
                              {format(job.collection_time, AMERICAN_DATETIME_FORMAT)}
                            </span>
                          </section>
                          {job?.main_driver && (
                            <section className={styles.detailsBlockItem}>
                              <span className={styles.detailsBlockLabel}>
                                Driver:
                              </span>
                              <span className={styles.detailsBlockValue}>
                                {job.main_driver.name}
                              </span>
                            </section>
                          )}
                        </div>
                        <div className={styles.detailsBlockGroup}>
                          <section className={styles.detailsBlockItem}>
                            <span className={styles.detailsBlockLabel}>
                              Pick-up address:
                            </span>
                            <span className={styles.detailsBlockValue}>
                              {job.address}
                            </span>
                          </section>
                          {job?.co_driver && (
                            <section className={styles.detailsBlockItem}>
                              <span className={styles.detailsBlockLabel}>
                                Co-driver:
                              </span>
                              <span className={styles.detailsBlockValue}>
                                {job.co_driver.name}
                              </span>
                            </section>
                          )}
                        </div>
                        {job?.distance_in_miles && (
                          <div className={styles.detailsBlockGroup}>
                            <section className={styles.detailsBlockItem}>
                              <span className={styles.detailsBlockLabel}>
                                Distance:
                              </span>
                              <span className={styles.detailsBlockValue}>
                                {job.distance_in_miles}
                              </span>
                            </section>
                          </div>
                        )}
                        <div className={styles.detailsBlockGroup}>
                          <section className={styles.detailsBlockItem}>
                            <span className={styles.detailsBlockLabel}>
                              Status:
                            </span>
                            {Object.keys(job.activity).map((key) => (
                              <span className={styles.statusBlock}>
                                <span>{`${formatName(key).replace(" At", "")}: `}</span>
                                <span className={styles.statusTime}>
                                  {format(job.activity[key], AMERICAN_DATETIME_FORMAT)}
                                </span>
                              </span>
                            ))}
                          </section>
                        </div>
                        {job.notes && (
                          <div className={styles.detailsBlockGroup}>
                            <section className={styles.detailsBlockItem}>
                              <span className={styles.detailsBlockLabel}>
                                Note:
                              </span>
                              <span className={styles.detailsBlockValue}>
                                {job.notes}
                              </span>
                            </section>
                          </div>
                        )}
                      </div>
                    ))}
                  </Accordion>
                )}
                {jobs.filter((job) => job.job_type === "dropoff").length > 0 && (
                  <Accordion title="Drop-off details">
                    {jobs.filter((job) => job.job_type === "dropoff").map((job) => (
                      <div className={styles.detailsBlock}>
                        <div className={styles.detailsBlockGroup}>
                          <section className={styles.detailsBlockItem}>
                            <span className={styles.detailsBlockLabel}>
                              Drop-off:
                            </span>
                            <span className={styles.detailsBlockValue}>
                              {format(job.collection_time, AMERICAN_DATETIME_FORMAT)}
                            </span>
                          </section>
                          {job?.main_driver && (
                            <section className={styles.detailsBlockItem}>
                              <span className={styles.detailsBlockLabel}>
                                Driver:
                              </span>
                              <span className={styles.detailsBlockValue}>
                                {job.main_driver.name}
                              </span>
                            </section>
                          )}
                        </div>
                        <div className={styles.detailsBlockGroup}>
                          <section className={styles.detailsBlockItem}>
                            <span className={styles.detailsBlockLabel}>
                              Drop-off address:
                            </span>
                            <span className={styles.detailsBlockValue}>
                              {job.address}
                            </span>
                          </section>
                          {job?.co_driver && (
                            <section className={styles.detailsBlockItem}>
                              <span className={styles.detailsBlockLabel}>
                                Co-driver:
                              </span>
                              <span className={styles.detailsBlockValue}>
                                {job.co_driver.name}
                              </span>
                            </section>
                          )}
                        </div>
                        {job?.distance_in_miles && (
                          <div className={styles.detailsBlockGroup}>
                            <section className={styles.detailsBlockItem}>
                              <span className={styles.detailsBlockLabel}>
                                Distance:
                              </span>
                              <span className={styles.detailsBlockValue}>
                                {job.distance_in_miles}
                              </span>
                            </section>
                          </div>
                        )}
                        <div className={styles.detailsBlockGroup}>
                          <section className={styles.detailsBlockItem}>
                            <span className={styles.detailsBlockLabel}>
                              Status:
                            </span>
                            {Object.keys(job.activity).map((key) => (
                              <span className={styles.statusBlock}>
                                <span>{`${formatName(key).replace(" At", "")}: `}</span>
                                <span className={styles.statusTime}>
                                  {format(job.activity[key], AMERICAN_DATETIME_FORMAT)}
                                </span>
                              </span>
                            ))}
                          </section>
                        </div>
                        {job.notes && (
                          <div className={styles.detailsBlockGroup}>
                            <section className={styles.detailsBlockItem}>
                              <span className={styles.detailsBlockLabel}>
                                Note:
                              </span>
                              <span className={styles.detailsBlockValue}>
                                {job.notes}
                              </span>
                            </section>
                          </div>
                        )}
                      </div>
                    ))}
                  </Accordion>
                )}
                {jobs.filter((job) => job.job_type === "mobile_technician").length > 0 && (
                  <Accordion title="Mobile Technician details">
                    {jobs.filter((job) => job.job_type === "mobile_technician").map((job) => (
                      <div className={styles.detailsBlock}>
                        <div className={styles.detailsBlockGroup}>
                          <section className={styles.detailsBlockItem}>
                            <span className={styles.detailsBlockLabel}>
                              Mobile Technician time:
                            </span>
                            <span className={styles.detailsBlockValue}>
                              {format(job.collection_time, AMERICAN_DATETIME_FORMAT)}
                            </span>
                          </section>
                          {job?.technician && (
                            <section className={styles.detailsBlockItem}>
                              <span className={styles.detailsBlockLabel}>
                                Technician:
                              </span>
                              <span className={styles.detailsBlockValue}>
                                {job.technician.name}
                              </span>
                            </section>
                          )}
                        </div>
                        <div className={styles.detailsBlockGroup}>
                          <section className={styles.detailsBlockItem}>
                            <span className={styles.detailsBlockLabel}>
                              Mobile Technician address:
                            </span>
                            <span className={styles.detailsBlockValue}>
                              {job.address}
                            </span>
                          </section>
                        </div>
                        {job?.distance_in_miles && (
                          <div className={styles.detailsBlockGroup}>
                            <section className={styles.detailsBlockItem}>
                              <span className={styles.detailsBlockLabel}>
                                Distance:
                              </span>
                              <span className={styles.detailsBlockValue}>
                                {job.distance_in_miles}
                              </span>
                            </section>
                          </div>
                        )}
                        <div className={styles.detailsBlockGroup}>
                          <section className={styles.detailsBlockItem}>
                            <span className={styles.detailsBlockLabel}>
                              Status:
                            </span>
                            {Object.keys(job.activity).map((key) => (
                              <span className={styles.statusBlock}>
                                <span>{`${formatName(key).replace(" At", "")}: `}</span>
                                <span className={styles.statusTime}>
                                  {format(job.activity[key], AMERICAN_DATETIME_FORMAT)}
                                </span>
                              </span>
                            ))}
                          </section>
                        </div>
                        {job.notes && (
                          <div className={styles.detailsBlockGroup}>
                            <section className={styles.detailsBlockItem}>
                              <span className={styles.detailsBlockLabel}>
                                Note:
                              </span>
                              <span className={styles.detailsBlockValue}>
                                {job.notes}
                              </span>
                            </section>
                          </div>
                        )}
                      </div>
                    ))}
                  </Accordion>
                )}
                {questionnaireAnswers && (
                  <Accordion title="Questionnaire Answers">
                    {questionnaireSource?.source && (
                      <div className={styles.questionariesSource}>
                        <span className={styles.sourceLabel}>
                          {
                            questionnaireSource?.source === "customer"
                              ? `Created by: ${questionnaireSource?.source}`
                              : `Created by: ${questionnaireSource?.created_by_name} (${questionnaireSource?.source})`
                          }
                        </span>
                      </div>
                    )}
                    <div className={styles.questionariesAnswers}>
                      {Object.entries(questionnaireAnswers).map(([question, answer]) => (
                        <div className={styles.detailsBlock}>
                          <section className={styles.detailsBlockItem}>
                            <span className={styles.detailsBlockLabel}>
                              {question}
                            </span>
                            <span className={styles.detailsBlockValue}>
                              {answer}
                            </span>
                          </section>
                        </div>
                      ))}
                    </div>
                  </Accordion>
                )}
                <VideosPanel booking={booking} />
              </section>
              <div className={styles.sidebar}>
                <BookingDetailsSummary booking={booking} />
                {transport && (
                  <AlternativeTransportationPanel booking={booking} />
                )}
                <InternalNote booking={booking} />
                {customer?.last_visit_advisor && customer?.last_visited_at && (
                  <LastAdvisor customer={customer} />
                )}
                {!booking.repair_order_number && (
                  <div className={styles.sidebarButtons}>
                    {!isNotShown && !isCanceled && (
                      <Button
                        variant="dark"
                        fullWidth
                        disabled={!isChangeable}
                        onClick={() => setIsModifyBooking(true)}
                      >
                        Modify / Reschedule
                      </Button>
                    )}
                    {isTesting && (
                      <Button
                        variant="dark"
                        fullWidth
                        disabled={!isBookable}
                        onClick={() => {
                          onScheduleNewBooking();
                          history.push("/scheduling/process");
                        }}
                      >
                        New appointment
                      </Button>

                    )}
                    {isNotShown && isToday(booking.appointment_datetime) && (
                      <Button
                        variant="success"
                        fullWidth
                        onClick={() => {
                          updateToArrived();
                        }}
                      >
                        Customer arrived
                      </Button>
                    )}
                    {isQuestionnaireShown && (
                      <Button
                        variant="dark"
                        fullWidth
                        onClick={() => setIsQuestionnaireModalOpen(true)}
                      >
                        Questionnaire
                      </Button>
                    )}
                    {!isNotShown && !isCanceled && (
                      <Button
                        variant="destructive-outline"
                        leftIcon="bin"
                        fullWidth
                        onClick={() => setIsDeleteModalOpen(true)}
                      >
                        <p>Cancel appointment</p>
                      </Button>
                    )}
                  </div>
                )}
              </div>
            </section>
          </>
        )}
        {isModifyBooking && (
          <ModifyBookingPage
            onClose={() => setIsModifyBooking(false)}
          />
        )}
      </section>
      {isDeleteModalOpen && (
        <Modal
          title="Wait. Are you sure?"
          text="Booking data will be lost. Customer will be notified about cancelation."
          cancelButtonText="No"
          submitButtonText="Yes"
          submitButtonVariant="destructive"
          cancelButtonVariant="dark"
          size="small"
          onSubmit={() => {
            onDeleteBooking(booking.id);
            setIsDeleteModalOpen(false);
          }}
          onCancel={() => setIsDeleteModalOpen(false)}
        />
      )}
      {isCheckinModalOpen && (
        <CheckinModal
          onClose={() => {
            setICheckinModalOpen(false);
            onReset();
          }}
        />
      )}
      {isQuestionnaireModalOpen && (
        <QuestionnaireModal
          onClose={() => {
            setIsQuestionnaireModalOpen(false);
          }}
        />
      )}
      {editDropOffJob && (
        <EditDropOffJobModal
          appointmentId={appointmentId}
          pickUpJob={activePickUpJob}
          dropOffJob={activeDropOffJob}
          isDropOff={isDropOff}
          selectedAdvisor={selectedAdvisor}
          onClose={() => setEditDropOffJob(false)}
          customerAddress={prepareCustomerPickupAddress(currentCustomer)}
          customerAddressData={prepareCustomerPickupAddressData(currentCustomer)}
        />
      )}
    </section>
  );
};

BookingDetailsPage.propTypes = {
  fetchBookingDetails: func,
  saveAppointment: func.isRequired,
  createRo: func.isRequired,
  onDeleteBooking: func.isRequired,
  inviteToCheckin: func.isRequired,
  onInitializeCheckin: func.isRequired,
  onInitializeChat: func.isRequired,
  isLoading: bool,
  isTriggerSmsLoading: bool,
  saveAppointmentLoading: bool,
  booking: objectOf(string),
  error: objectOf(string),
  saveAppointmentError: objectOf(string),
  match: matchType,
  selectedAdvisor: serviceAdvisorPropType,
  isDropOff: bool.isRequired,
  isMobileTechnician: bool.isRequired,
  isCheckinLoading: bool.isRequired,
  isCheckedIn: bool.isRequired,
  isRoGenerated: bool.isRequired,
  updatedWithoutDmsNotification: func.isRequired,
  onScheduleNewBooking: func.isRequired,
  checkinError: bool.isRequired,
  resetBookingDetailsState: func.isRequired,
  isChatInitialized: bool.isRequired,
  isSmsSent: bool,
  isAutoGenerateROenabled: bool,
  onReset: func.isRequired,
  vehicleData: objectOf(node),
  retrieveDrivers: func.isRequired,
  updateToArrived: func.isRequired,
  history: arrayOf(string).isRequired,
  currentCustomer: objectOf(string).isRequired,
  questionnaires: arrayOf(shape).isRequired,
};

BookingDetailsPage.defaultProps = {
  fetchBookingDetails: () => {},
  isLoading: false,
  saveAppointmentLoading: false,
  booking: null,
  error: null,
  saveAppointmentError: null,
  match: null,
  selectedAdvisor: {},
  isTriggerSmsLoading: false,
  isSmsSent: false,
  isAutoGenerateROenabled: false,
  vehicleData: {},
};

const mapStateToProps = (state) => {
  return {
    isLoading: bookingDetailsLoadingStateSelector(state),
    isTriggerSmsLoading: bookingDetailsIsTriggerSmsLoadingSelector(state),
    isSmsSent: bookingDetailsIsSmsSentSelector(state),
    booking: bookingDetailsDataSelector(state),
    error: bookingDetailsErrorSelector(state),
    selectedAdvisor: bookingDetailsSelectedAdvisorSelector(state),
    saveAppointmentLoading: bookingDetailsSaveAppointmentLoadingSelector(state),
    saveAppointmentError: bookingDetailsSaveAppointmentErrorSelector(state),
    updatedWithoutDmsNotification: bookingDetailsUpdatedWithoutDmsNotificationSelector(state),
    isDropOff: bookingDetailsIsDropOffSelector(state),
    isMobileTechnician: bookingDetailsisMobileTechnicianSelector(state),
    isCheckinLoading: checkinAppointmentLoadingSelector(state),
    checkinError: checkinAppointmentErrorSelector(state),
    isCheckedIn: bookingDetailsIsCheckedInSelector(state),
    isChatInitialized: chatIsInitializedSelector(state),
    isRoGenerated: bookingDetailsIsROGeneratedSelector(state),
    sendSms: bookingDetailsSendSmsSelector(state),
    isAutoGenerateROenabled: settingsIsAutoGenerateROSelector(state),
    vehicleData: bookingVehicleData(state),
    currentCustomer: bookingDetailsCustomerSelector(state),
    questionnaires: bookingDetailsQuestionnairesSelector(state),
  };
};

const actions = {
  fetchBookingDetails: retrieveBookingDetails,
  onDeleteBooking: deleteBooking,
  saveAppointment: updateAppointment,
  onInitializeCheckin: initializeCheckIn,
  onReset: exit,
  onInitializeChat: initializeChat,
  inviteToCheckin: triggerCheckinSms,
  resetBookingDetailsState: resetBookingDetails,
  onScheduleNewBooking: scheduleNewBooking,
  createRo: generateRO,
  retrieveDrivers: retrieveDealershipDrivers,
  updateToArrived: updateBookingToArrived,
};

export default connect(mapStateToProps, actions)(BookingDetailsPage);
